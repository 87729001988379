import i18n from 'i18next'
import axios from 'axios'
import {store} from '../reducers'

const lang = localStorage.getItem('site_lang') || 'ar'
axios.defaults.baseURL = 'https://new.thecontentapp.net/dashboard/api/'
axios.interceptors.response.use(
  (res) => res.data,
  (err) => Promise.reject(err.response)
)

i18n.on('languageChanged', function (lng) {
  localStorage.setItem('site_lang', lng)
  localStorage.setItem('i18nextLng', lng)
  if (lng === 'en') {
    document.body.dir = 'ltr'
  } else {
    document.body.dir = 'rtl'
  }
  document.documentElement.lang = lang

  window.location.reload()
})


const userDate = store.getState().authStoreState.token

const getAuthHeaderLang = () => ({
  Authorization: `Bearer ${userDate}`,
  'Accept-Language': lang,
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'content-type': 'multipart/form-data',
})

export const makeRequest = {
  async ApiService(url, item) {
    try {
      return await axios.get(url, { headers: getAuthHeaderLang() });
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Implement exponential backoff here
        const waitTime = Math.pow(2, error.response.headers['retry-after']);
        console.log(`Rate limited. Retrying in ${waitTime} seconds.`);
        await new Promise(resolve => setTimeout(resolve, waitTime * 1000));
        return makeRequest.ApiService(url, item); // Retry the request
      } else {
        throw error; // Re-throw other errors
      }
    }
  },
}

export const ApiService = {
  landingPage(item) {
    return axios.get('portal/header_section', {headers: getAuthHeaderLang()})
  },
  Services(item) {
    return axios.get('media/webServices', {headers: getAuthHeaderLang()})
  },
  AboutSection(item) {
    return axios.get('portal/about_section', {headers: getAuthHeaderLang()})
  },
  ServicesSection(item) {
    return axios.get('portal/services_section', {headers: getAuthHeaderLang()})
  },
  VisionSection(item) {
    return axios.get('portal/vision', {headers: getAuthHeaderLang()})
  },
  ImpactSection(item) {
    return axios.get('portal/impact', {headers: getAuthHeaderLang()})
  },
  DownloadSection(item) {
    return axios.get('portal/download_section', {headers: getAuthHeaderLang()})
  },
  AgentsSection(item) {
    return axios.get('portal/clients', {headers: getAuthHeaderLang()})
  },
  FooterSection(item) {
    return axios.get('portal/footer_section', {headers: getAuthHeaderLang()})
  },
  FooterLinks(item) {
    return axios.get('portal/footer_links', {headers: getAuthHeaderLang()})
  },
  ContentSection(item) {
    return axios.get('portal/content_section', {headers: getAuthHeaderLang()})
  },
  AboutPage(item) {
    return axios.get('portal/about_page', {headers: getAuthHeaderLang()})
  },
  Settings(item) {
    return axios.get('media/home/settings', {headers: getAuthHeaderLang()})
  },
  postContactUs(item) {
    return axios.post('media/contactUs', item, {headers: getAuthHeaderLang()})
  },
  getProfile(item) {
    return axios.get('media/user/profile', {
      headers: getAuthHeaderLang(),
    })
  },
  getServices(item) {
    return axios.get('media/services', {headers: getAuthHeaderLang()})
  },
  getChildService(item) {
    return axios.post('services/subServices', item, {
      headers: getAuthHeaderLang(),
    })
  },
  getSubService(parent_id) {
    return axios.get(`services/subServices/${parent_id}`, {
      headers: getAuthHeaderLang(),
    })
  },
  getDataOrderStep1(item) {
    return axios.post('order/getDataOrderStep1', item, {
      headers: getAuthHeaderLang(),
    })
  },
  OrderStepOne(item) {
    return axios.post('order/storeStepOne', item, {
      headers: getAuthHeaderLang(),
    })
  },
  OrderStepOneData(item) {
    return axios.post('order/getOrderStepOneData', item, {
      headers: getAuthHeaderLang(),
    })
  },
  getItemStepTwo(item) {
    return axios.post('order/get_item_step_two', item, {
      headers: getAuthHeaderLang(),
    })
  },
  OrderStepTwo(item) {
    return axios.post('order/storeStepTwo', item, {
      headers: getAuthHeaderLang(),
    })
  },
  uploadFile(item) {
    return axios.post('order/uploadFile', item, {headers: getAuthHeaderLang()})
  },
  uploadFileChat(item) {
    return axios.post('chat/upload', item, {headers: getAuthHeaderLang()})
  },
  uploadAudioRecording(item) {
    return axios.post('order/uploadAudioRecording', item, {
      headers: getAuthHeaderLang(),
    })
  },
  MyOrders(item) {
    return axios.post('user/orders', item, {headers: getAuthHeaderLang()})
  },
  DetailsOrder(item) {
    return axios.post('order/details', item, {
      headers: getAuthHeaderLang(),
    })
  },
  cancelOrder(item) {
    return axios.post('order/cancel', item, {
      headers: getAuthHeaderLang(),
    })
  },
  unComplete(item) {
    return axios.post('order/inComplete', item, {
      headers: getAuthHeaderLang(),
    })
  },
  completeOrder(item) {
    return axios.post('order/complete', item, {
      headers: getAuthHeaderLang(),
    })
  },
  userComplaint(item) {
    return axios.post('order/makeComplaint', item, {
      headers: getAuthHeaderLang(),
    })
  },
  GetuserOrganizations(item) {
    return axios.get('media/organizations', item, {headers: getAuthHeaderLang()})
  },
  GetuserComplaint(item) {
    return axios.get('user/tickets', {headers: getAuthHeaderLang()})
  },
  GetchatComplaint(item) {
    return axios.post('complaint/details', item, {headers: getAuthHeaderLang()})
  },
  replayToComplaint(item) {
    return axios.post('complaint/sendMessage', item, {
      headers: getAuthHeaderLang(),
    })
  },
  getChat(item) {
    return axios.post('chat/add', item, {headers: getAuthHeaderLang()})
  },
  getChatProfileProvider(item) {
    return axios.post('chat/getChatProfileProvider', item, {
      headers: getAuthHeaderLang(),
    })
  },
  getTerms() {
    return axios.get('media/fixedPages', {
      headers: getAuthHeaderLang(),
    })
  },
  getPrivacy(item) {
    return axios.post('mediaSettings/Privacy', item, {
      headers: getAuthHeaderLang(),
    })
  },
  closeComplaint(item) {
    return axios.post('order/closeComplaint', item, {
      headers: getAuthHeaderLang(),
    })
  },
  getNotification() {
    return axios.get('notifications', {
      headers: getAuthHeaderLang(),
    })
  },
  organizationOptions(item) {
    return axios.get('media/organizationOptions', {headers: getAuthHeaderLang()})
  },
  userGuide(item) {
    return axios.get('portal/userGuide', {headers: getAuthHeaderLang()})
  },
}