import React, {useEffect, useState} from 'react'
import HeaderPages from '../header/HeaderPages'
import {makeRequest} from '../../services/data.service'
import {HeroItem} from './HeroType'

export type IHeaderSettings = {
  logo?: string
  website_name?: string
  primary_color?: string
}

function HeroSection() {
  const [heroData, setHeroData] = useState<HeroItem>()
  const [settingsData, setSettingsData] = useState<IHeaderSettings>()

  useEffect(() => {
    makeRequest.ApiService('media/home')
    .then((response: any) => {
      setHeroData(response.data)
    })
    .catch((err: any) => {
      console.log(err)
    })
  }, [])

  const mystyle = {
    backgroundImage: `url("${heroData?.intro_section?.image}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  }

  return (
    <React.Fragment>
      <HeaderPages />
      <div
        className={`tcon-hero-section pt-14 bg-[url('${heroData && heroData?.intro_section?.image}')] `}
        style={mystyle}
      >
        <div className="relative isolate mx-auto px-6 pt-14 xl:container lg:px-8">
          <div className="flex flex-wrap justify-between p-6 md:flex-nowrap lg:px-8">
            <div className="py-32 sm:py-48 lg:py-56">
              <div className="text-start">
                <h1 className="text-4xl font-bold leading-10 tracking-tight text-white md-text-6xl lg:text-6xl">
                  {heroData && heroData?.intro_section.title}
                </h1>
                <p className="mt-9 text-4xl leading-10 text-white">
                  {heroData && heroData?.intro_section.description}
                </p>
              </div>
            </div>
            {/* <img className="max-w-xs lg:max-w-xl" src={heroData && heroData.image} alt={heroData && heroData.title} /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HeroSection
