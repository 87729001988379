// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'
import {getDatabase} from 'firebase/database'
import {getStorage} from 'firebase/storage'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3zOZoXB13YYdFZhQM5KGQ-VZOePLzBJ4",
  authDomain: "thecontentappv2.firebaseapp.com",
  databaseURL: "https://thecontentappv2-default-rtdb.firebaseio.com",
  projectId: "thecontentappv2",
  storageBucket: "thecontentappv2.appspot.com",
  messagingSenderId: "975889510708",
  appId: "1:975889510708:web:d1d173e0b0dd1bcfb57561",
  measurementId: "G-T2QK4L72XS"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getDatabase(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app)

export const getTokenvalue = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      'BGbpQzPdXV1Jbb-VD42qubYRFGThjiC_o-0MsnhJelhL2cy5DWKnF__1sLzVc-g9mRfRoX49ViWP2h7pR4PTgzQ',
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken)
        localStorage.setItem('fcm_token', currentToken)
        setTokenFound(true)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        resolve(payload);
        console.log('Message received. ', payload);
      });
    } catch (error) {
      reject(error);
    }
  });