import React, {Fragment, useEffect, useState} from 'react'
import {makeRequest} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {useTranslation} from 'react-i18next'
import './main.css'
import closeBtn from '../../assets/images/icon/close.svg'
import img6 from '../../assets/images/organizations/صندوق-دعم-الجمعيات.jpg'
import img7 from '../../assets/images/organizations/007-جمعية-لزم-لتطوير-العمل التطوعي.png'
import img8 from '../../assets/images/organizations/008-جمعية-تجربة-العميل.png'
import img9 from '../../assets/images/organizations/009-جمعية-ذوي-اضطرابات-النطق-واللغة-نطق.webp'
import img10 from '../../assets/images/organizations/010-جمعية-التنمية-الأهلية-بحي-الحمراء.jpg'
import img11 from '../../assets/images/organizations/011-جمعية-التنمية-الأهلية-بحي-الملك-فهد.png'
import img12 from '../../assets/images/organizations/012-جمعية-جدد-لتنمية-القطاع-الغير-ربحي.png'
import img13 from '../../assets/images/organizations/013-جمعية-همم-الشبابية.png'
import img14 from '../../assets/images/organizations/014-جمعية-التطوع-الصحي-أثر.png'
import img16 from '../../assets/images/organizations/016-جمعية-أمس-أصدقاء-مرضى-السرطان.png'
import img17 from '../../assets/images/organizations/017-جمعية-البر-الخيرية-بمحافظة-الخرج.png'
import img19 from '../../assets/images/organizations/019-جمعية-مكارم-الأخلاق.png'
import img20 from '../../assets/images/organizations/020-جمعية-مسارات-ورحلات-المشي.png'
import img21 from '../../assets/images/organizations/021-جمعية-ركن-الحوار.png'
import img23 from '../../assets/images/organizations/023-جمعية-بنيان-الخيرية-النسائية-للإسكان.png'
import img24 from '../../assets/images/organizations/024-جمعية-الرضاعة-الطبيعية.png'
import {Link} from 'react-router-dom'

export const CommonOrganizations = () => {
  const {t} = useTranslation()
  const [contentData, setContentData] = useState<any>()
  const [contentDes, setContentDes] = useState<any>()
  const [show, setShow] = useState<any>(false)
  const [showPop, setShowPop] = useState<any>(false)

  useEffect(() => {
    makeRequest.ApiService('media/organizations').then((response: any) => {
      console.log(response.data, 'response.data');
      setContentData(response.data)
    })
  }, [])

  const handelClickdocs = (id: number) => {
    makeRequest.ApiService(`media/organization/${id}`).then((response: any) => {
      setContentDes(response.data)
    })
    setShow(!show)
  }

  const toggleModal = () => {
    setContentDes(null)
    setShow(!show)
  }

  const toggleModalPop = () => {
    setShowPop(!show)
    console.log('click')
  }
  const toggleModalClose = () => {
    setShowPop(false)
    console.log('close')
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('commonOrganizations')} />
        <div className="tcon-about-section py-20">
          <div className="relative isolate mx-auto xl:container">
            <div className="bg-white py-10">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                  <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t('supporters')}
                  </h2>
                </div>
                <div className="mx-auto my-16 lg:mt-24">
                  <dl
                    className="flex justify-center"
                    onClick={toggleModalPop}
                  >
                    <div className="relative rounded-lg p-5 shadow-lg w-1/3">
                      <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                        <img
                          src={img6}
                          className="m-auto mb-5 h-24"
                          alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                        />
                        جمعية الإعلاميين السعوديين - إعلام
                      </dt>
                    </div>
                  </dl>
                  {showPop && (
                    <div className="modal-overlay z-10">
                      <div className="modal">
                        <button
                          onClick={toggleModalClose}
                          className="close-button"
                        >
                          <img className="h-4 w-4" src={closeBtn} alt="close" />
                        </button>
                        <div className="py-5">
                          <header className="text-center">
                            <img
                              className=" m-auto h-32"
                              src={img6}
                              alt="صندوق دعم الجمعيات"
                            />

                            <h2 className="my-6 text-center font-semibold">
                              صندوق دعم الجمعيات
                            </h2>
                          </header>

                          {/* <div
                              className="mb-6"
                              dangerouslySetInnerHTML={{
                                __html: contentDes.description,
                              }}
                            ></div> */}
                          <div className="mb-6">
                            <p>
                              صندوق دعم الجمعيات رؤية مجتمعية ورسالة وطنية تهدف
                              إلى الاستدامة.. التنمية... التمكين..
                            </p>
                          </div>
                          <ul>
                            <li>
                              <b>العنوان: </b>
                              مبنى رقم 2535 شارع هشام بن عبد الملك، 7033 حي
                              المرسلات، الرياض 12461، المملكة العربية السعودية
                            </li>

                            {/* {contentDes.commissioner_name && (
                              <li>
                                <b>اسم المفوض: </b>
                                {contentDes.commissioner_name}
                              </li>
                            )}
                            {contentDes.commissioner_attribute && (
                              <li>
                                <b>صفة المفوض: </b>
                                {contentDes.commissioner_attribute}
                              </li>
                            )}
                            {contentDes.phone && (
                              <li>
                                <b>الجوال: </b>
                                {contentDes.phone}
                              </li>
                            )} */}
                            <li>
                              <b>البريد الإلكتروني: </b>
                              care@asf.gov.sa
                            </li>
                            {/* {contentDes.website && (
                              <li>
                                <b>الموقع الإلكتروني: </b>
                                <Link to={contentDes.website} target="_blank">
                                  {contentDes.website}
                                </Link>
                              </li>
                            )} */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mx-auto max-w-2xl lg:text-center">
                  <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {t('partners')}
                  </h2>
                </div>
                <div className="mx-auto my-16 lg:mt-24">
                  <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none md:grid-cols-2 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                    {contentData &&
                      contentData.map(
                        (item: any, index: number) =>
                          item.id !== 21 && (
                            <div
                              className="relative rounded-lg p-5 shadow-lg"
                              onClick={() => handelClickdocs(item.id)}
                              key={index}
                            >
                              <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                                <img
                                  src={item.image}
                                  className="m-auto mb-5 h-24"
                                  alt={item.name}
                                />
                                {item.name}
                              </dt>
                            </div>
                          )
                      )}

                    {/* <div className="relative rounded-lg p-5 shadow-lg">
                      <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                        <img
                          src={img2}
                          className="m-auto mb-5 h-24"
                          alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                        />
                        جمعية الإعلاميين السعوديين - إعلام
                      </dt>
                    </div> */}
                    {/* <div className="relative rounded-lg p-5 shadow-lg">
                      <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                        <img
                          src={img3}
                          className="m-auto mb-5 h-24"
                          alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                        />
                        جمعية البر الخيرية بالرياض
                      </dt>
                    </div> */}
                    {/* <div className="relative rounded-lg p-5 shadow-lg">
                      <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                        <img
                          src={img4}
                          className="m-auto mb-5 h-24"
                          alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                        />
                        جمعية بسمات للعناية بالأسنان
                      </dt>
                    </div>
                    <div className="relative rounded-lg p-5 shadow-lg">
                      <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                        <img
                          src={img5}
                          className="m-auto mb-5 h-24"
                          alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                        />
                        جمعية بيتي للإسكان التنموي
                      </dt>
                    </div> */}
                    {/* {contentData && (
                      <Fragment>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img6}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية جليس
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img7}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية لزم لتطوير العمل التطوعي
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img8}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية تجربة العميل
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img9}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية ذوي اضطرابات النطق واللغة (نطق)
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img10}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية التنمية الأهلية بحي الحمراء
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img11}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية التنمية الأهلية بحي الملك فهد
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img12}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية جدد لتنمية القطاع الغير ربحي
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img13}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية همم الشبابية
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img14}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية التطوع الصحي أثر
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img16}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية أمس أصدقاء مرضى السرطان
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img17}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية البر الخيرية بمحافظة الخرج
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img19}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية رأس المال الجريئ والملكية الخاصة
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img20}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية مكارم الأخلاق
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img21}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية مسارات ورحلات المشي
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img23}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية تكنولوجيا الهندسة الطبية
                          </dt>
                        </div>
                        <div className="relative rounded-lg p-5 shadow-lg">
                          <dt className="text-center text-base font-semibold leading-7 text-gray-900">
                            <img
                              src={img24}
                              className="m-auto mb-5 h-24"
                              alt="مجلس الجمعيات الأهلية بمنطقة الرياض"
                            />
                            جمعية بنيان الخيرية النسائية للإسكان
                          </dt>
                        </div>
                      </Fragment>
                    )} */}
                  </dl>
                  {show && (
                    <div className="modal-overlay z-10">
                      <div className="modal">
                        <button onClick={toggleModal} className="close-button">
                          <img className="h-4 w-4" src={closeBtn} alt="close" />
                        </button>
                        {contentDes && (
                          <div className="py-5">
                            <header className="text-center">
                              {contentDes.image && (
                                <img
                                  className=" m-auto h-32"
                                  src={contentDes.image}
                                  alt={contentDes.name}
                                />
                              )}
                              {contentDes.name && (
                                <h2 className="my-6 text-center font-semibold">
                                  {contentDes.name}
                                </h2>
                              )}
                            </header>

                            <div
                              className="mb-6"
                              dangerouslySetInnerHTML={{
                                __html: contentDes.description,
                              }}
                            ></div>
                            <ul>
                              {contentDes.location && (
                                <li>
                                  <b>مقرها: </b>
                                  {contentDes.location}
                                </li>
                              )}
                              {contentDes.commissioner_name && (
                                <li>
                                  <b>اسم المفوض: </b>
                                  {contentDes.commissioner_name}
                                </li>
                              )}
                              {contentDes.commissioner_attribute && (
                                <li>
                                  <b>صفة المفوض: </b>
                                  {contentDes.commissioner_attribute}
                                </li>
                              )}
                              {contentDes.phone && (
                                <li>
                                  <b>الجوال: </b>
                                  {contentDes.phone}
                                </li>
                              )}
                              {contentDes.email && (
                                <li>
                                  <b>الإيميل: </b>
                                  {contentDes.email}
                                </li>
                              )}
                              {contentDes.website && (
                                <li>
                                  <b>الموقع الإلكتروني: </b>
                                  <Link to={contentDes.website} target="_blank">
                                    {contentDes.website}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CommonOrganizations
