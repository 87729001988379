import React, {useState} from 'react'
import {useFormik} from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { Footer } from '../../components/Footer/Footer'
import { ContactUsItem } from './ContactUsType'
import axios from 'axios'

export const ContactUs = () => {
    const {t} = useTranslation();
    const [contactData, setContactData] = useState<ContactUsItem>()
    const [success, setSuccess] = useState(false)


    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

    const SignupSchema = Yup.object().shape({
      name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required(t('nameerror')),
      email: Yup.string().email(t('emailerror')).required(t('emailerror')),
      phone: Yup.string().matches(phoneRegExp, t('phoneerror')).required(t('phoneerror')),
      message: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required(t('massageerror')),

    });


  const ContactUs = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      axios.post('https://new.thecontentapp.net/dashboard/api/media/contactUs', {
        fullName: values.name,
        phone: values.phone,
        email: values.email,
        message: values.message,
      })
      .then((response : any) => {
        setSuccess(true);
        setContactData(response.data);
        if(response.status === "success") {
          setTimeout(() => {
            setSuccess(false);
            
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
  })

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container sm:mx-auto mt-20">
        <Breadcrumb title={t('contactus')} />
        <div className="tcon-about-section py-10 lg:py-10">
          <div className="relative isolate mx-auto xl:container">
            <form onSubmit={ContactUs.handleSubmit}>
              <div className="space-y-12">
                <div className="rounded-xl border-gray-900/10 pb-12 p-5 lg:p-16 bg-slate-100">
                  <h2 className="text-center text-xl font-semibold leading-7 text-gray-900">
                    {t('contactus')}
                  </h2>

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t('fullname')}
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={ContactUs.handleChange}
                          onBlur={ContactUs.handleBlur}
                          value={ContactUs.values.name}  
                          placeholder={t('fullname')}
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {ContactUs.errors.name && ContactUs.touched.name && <div className="text-red-600 text-sm mt-2">{ContactUs.errors.name}</div>}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t('phonenumber')}
                      </label>
                      <div className="mt-2">
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          onChange={ContactUs.handleChange}
                          onBlur={ContactUs.handleBlur}
                          value={ContactUs.values.phone}  
                          placeholder="05xx xxx xxx"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {ContactUs.errors.phone && ContactUs.touched.phone && <div className="text-red-600 text-sm mt-2">{ContactUs.errors.phone}</div>}
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t('email')}
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          onChange={ContactUs.handleChange}
                          onBlur={ContactUs.handleBlur}
                          value={ContactUs.values.email}  
                          autoComplete="email"
                          placeholder={t('email')}
                          className="block w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      {ContactUs.errors.email && ContactUs.touched.email && <div className="text-red-600 text-sm mt-2">{ContactUs.errors.email}</div>}
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t('message')}
                      </label>
                      <div className="mt-2 h-80">
                        <textarea
                          id="message"
                          name="message"
                          onChange={ContactUs.handleChange}
                          onBlur={ContactUs.handleBlur}
                          value={ContactUs.values.message}  
                          placeholder={t('message')}
                          className="block w-full h-80 rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        ></textarea>
                      </div>
                      {ContactUs.errors.message && ContactUs.touched.message && <div className="text-red-600 text-sm mt-2">{ContactUs.errors.message}</div>}
                    </div>

                    <div className="col-span-full">
                        <button
                        type="submit"
                        className="bg-primary_color rounded-md bg-indigo-600 px-24 py-5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        {t('send')}
                        </button>

                    </div>
                  </div>
                </div>
              </div>
            </form>
            {success === true && <p className="tcon-msg p-4 mb-4 bottom-0 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400">{contactData?.msg}</p>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
