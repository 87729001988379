import React, {useEffect, useState} from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import { ApiService } from '../../services/data.service';
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import { Footer } from '../../components/Footer/Footer';
import { useTranslation } from 'react-i18next';

export const ServicesSub = () => {

  const {t} = useTranslation();
  const params = useParams();

  const [serviceData, setServiceData] = useState<any>()
  
  const serviceId = params.id;
  
  useEffect(() => {
    ApiService.getChildService({
      service_id: serviceId,
    })
    .then((response) => {
      setServiceData(response.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb title={t('services')} subTitle={t('subservices')} />
        <div className="tcon-about-section py-40">
            <div className="relative xl:container isolate mx-auto">
                <React.Fragment>
              <div id="services" className="bg-white py-15 sm:py-15">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      {t('subservices')}
                    </h2>
                  </div>
                  <div className="mx-auto my-16 lg:mt-24">
                    <dl className="grid max-w-xl grid-cols-1 md:grid-cols-2 md:max-w-none gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                      {serviceData && serviceData.map((item: any, index: number) => (
                      <Link to={`/create-order/${serviceId}/${item.id}`} state={{ parent_id: serviceId, service_id: item.id }} key={index}>
                        <div className="relative pl-20 shadow-lg p-5 rounded-lg" key={index}>
                          <dt className="text-base font-semibold leading-7 text-gray-900">
                            <div className="absolute left-5 top-6 flex h-10 w-10 items-center justify-center rounded-lg">
                              <img src={item.image} alt='Editing Content' />
                            </div>
                            {item.name}
                          </dt>
                          <dd className="mt-2 text-base leading-7 text-gray-600">
                          {item.description}
                          </dd>
                        </div>
                      </Link>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            </React.Fragment>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ServicesSub
