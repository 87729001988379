import {Fragment, forwardRef, useEffect} from 'react'
import {SvgIcon} from '../SvgIcon/Index'
import {useRecord} from './hook'
import deleteIcon from '../../assets/images/icon/delete.svg'
import mic from '../../assets/images/mic.png'


import './main.css'
import { useTranslation } from 'react-i18next'

type Props = {
  label?: string
  className?: string
  hasError?: boolean
  showAudio?: boolean
  showIcon?: boolean
  onChange?: (e: any) => void
}

export const AudioRecorder = forwardRef<HTMLInputElement, Props>(
  (
    {
      label = '',
      className = '',
      onChange,
      hasError = false,
      showIcon = false,
      showAudio = true,
    },
    ref
  ) => {
    const {recording, record, timer, onRecordAction, onDeleteRecord} =
      useRecord()

    // On record change
    useEffect(() => {
      onChange?.(record)
    })

    const {t} = useTranslation()


    return (
      <Fragment>
        <div
          className={`flex w-full cursor-pointer flex-col gap-y-4 ${className}`}
          ref={ref}
          onClick={onRecordAction}
        >
          {label ? (
            <p className="flex font-medium md:text-lg">
              <SvgIcon name="microphone" className="h-6 w-6 fill-white" />{' '}
              {label}
            </p>
          ) : null}

          <div
            className={`recorder sm:h-13 relative flex w-full flex-col items-center gap-3 overflow-hidden pb-3 sm:flex-row sm:gap-0 sm:p-0  ${
              hasError ? 'hasError' : ''
            }`}
          >
            {showIcon ? (
              <img src={mic} alt="" width="w-10" />
            ) : (
              <div className="flex h-full w-full items-center justify-center px-4 sm:justify-start">
                <p className="text-xs text-gray-400 sm:text-sm md:text-base">
                  {t('startRecord')}
                </p>
              </div>
            )}

            {recording ? (
              <div className={`flex h-full items-center justify-center gap-x-3 pe-4 ${showIcon ? 'withIcon' : ''}`}>
                <p className="text-sm text-gray-400">{timer}</p>
                <p className="recorder-running h-4 w-4 rounded-full bg-red-600"></p>
              </div>
            ) : null}

            {record && !recording ? (
              <div className="flex h-full items-center justify-center gap-x-3 pe-3">
                <button
                  type="button"
                  className="flex h-4 w-4 items-center justify-center rounded-full  text-white"
                  onClick={onDeleteRecord}
                >
                  <img src={deleteIcon} alt="delete" />
                  <SvgIcon name="delete" className="h-5 w-5" />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {record && showAudio === true ? (
          <audio src={record?.url} className="mt-6 h-14 w-full" controls />
        ) : null}
      </Fragment>
    )
  }
)
