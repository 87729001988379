import React, {Fragment, useEffect, useState} from 'react'
import {ApiService} from '../../services/data.service'
import HeaderPages from '../../components/header/HeaderPages'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {Footer} from '../../components/Footer/Footer'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import upl from '../../assets/images/upl.svg'
import {useTranslation} from 'react-i18next'

export const TicketsSingle = () => {
  const {t} = useTranslation()
  const [ticketsChats, setTicketsChats] = useState<any>()
  const [ticketsComplaint, setTicketsComplaint] = useState<any>()
  const [lodding, setLodding] = useState<any>()
  const [success, setSuccess] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const tikets_ids = useParams()

  const complaint_id = tikets_ids.id
  const order_id = tikets_ids.order_id
  const user_id = localStorage.getItem('id_user')

  useEffect(() => {
    ApiService.GetchatComplaint({
      complaint_id: tikets_ids.id,
    })
      .then((response) => {
        setTicketsChats(response.data.chat)
        setTicketsComplaint(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const NewComplaint = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: async (values) => {
      setLodding(true)
      ApiService.replayToComplaint({
        complaint_id: complaint_id,
        message: values.message,
      })
        .then((response: any) => {
          if (response?.statusNumber === 200) {
            setSuccess(true)
            setTimeout(() => {
              window.location.reload()
            }, 500)
          } else {
            setSuccess(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  })

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  return (
    <div className="tcon-pages-wrapper">
      <HeaderPages />
      <div className="container mx-auto mt-20">
        <Breadcrumb
          title={`${t('responsesticket')} # ${ticketsComplaint?.id}`}
        />
        <div className="tcon-about-section m-auto lg:w-9/12 py-20 lg:py-10">
          <div className="chat-container">
            <div className="chat-header ">
              <div className="chat-head-user">
                <h3>
                  <span id="sender_name"></span>
                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {ticketsComplaint?.status}
                  </span>
                </h3>
              </div>
            </div>
            <div className="chat-body">
              {ticketsChats &&
                ticketsChats.map((ticket: any, index: any) => (
                  <Fragment>
                    <p className="flex justify-center" key={index}>
                      <span className="send_time">{ticket.date}</span>
                    </p>
                    {ticket.message_type_text === 'date' ? null : 
                      ticket &&
                      ticket.messages.map((ticket: any, index: any) => (
                      <div
                        className={
                          ticket.status === null
                            ? 'chat-to chat-item'
                            : 'chat-from chat-item'
                        }
                        id={index}
                        key={index}
                      >
                        <div>
                          <div className="img">
                            <div>
                              <img
                                src="https://thecontentapp.net/assets/images/users/default.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              {ticket.sender_type === 1
                                ? ticketsComplaint?.user_name
                                : ticketsComplaint?.complaint_user}
                            </div>
                            <div className="chat-item-text">
                              <Fragment>
                                <p className="text-md mb-3">{ticket.message}</p>
                                {ticket.message_type === 3 && (
                                  <Link to={ticket.attachment} target="_blank">
                                    <span>
                                      <img
                                        src={upl}
                                        alt={ticket.attachment_name}
                                      />
                                      <span className="block w-full">
                                        {ticket.attachment_name}
                                      </span>
                                      <span>{ticket.attachment_size}</span>
                                    </span>
                                  </Link>
                                )}
                              </Fragment>
                            </div>
                            <div className="chat-time">
                              {ticket.send_time_text}
                            </div>
                          </div>
                        </div>
                      </div>
                      ))}
                  </Fragment>
                ))}
            </div>

            <form id="send_ticket" onSubmit={NewComplaint.handleSubmit}>
              <div className="chat-footer">
                {file && (
                  <div id="added_files" className="row mb-5">
                    <div className="col-12">
                      <div className="file_itm flex items-center">
                        <div className="fle_icon me-3">
                          <span>
                            <img src={upl} alt="file icon" />
                          </span>
                        </div>
                        <div className="fle_txt">
                          <p>
                            <span>{file.name}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="textare-with-btns w-full">
                  <div className="chat-btns">
                    <label>
                      <i className="fas fa-paperclip"></i>
                      <input
                        type="file"
                        name="file"
                        id="chat_attachment"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                  <textarea
                    id="message"
                    name="message"
                    onChange={NewComplaint.handleChange}
                    onBlur={NewComplaint.handleBlur}
                    value={NewComplaint.values.message}
                    placeholder={t('message')}
                    className="mb-3 block h-20 w-full rounded-md border-0 px-2.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="text-md me-3 flex w-1/4 justify-center rounded-sm bg-lime-700 px-3.5 py-2.5 text-center font-semibold text-white"
                >
                  <span className="flex">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M7.11039 5.96028L16.1304 2.95028C20.1804 1.60028 22.3804 3.81028 21.0404 7.86028L18.0304 16.8803C16.0104 22.9503 12.6904 22.9503 10.6704 16.8803L9.78039 14.2003L7.10039 13.3103C1.04039 11.3003 1.04039 7.99028 7.11039 5.96028Z"
                        fill="white"
                      ></path>
                      <path
                        d="M12.1191 11.6296L15.9291 7.80957L12.1191 11.6296Z"
                        fill="white"
                      ></path>
                      <path
                        d="M12.1205 12.38C11.9305 12.38 11.7405 12.31 11.5905 12.16C11.3005 11.87 11.3005 11.39 11.5905 11.1L15.3905 7.28C15.6805 6.99 16.1605 6.99 16.4505 7.28C16.7405 7.57 16.7405 8.05 16.4505 8.34L12.6505 12.16C12.5005 12.3 12.3105 12.38 12.1205 12.38Z"
                        fill="white"
                      ></path>
                    </svg>
                    {t('send')}
                  </span>
                  {lodding && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="mr-2 inline h-4 w-4 animate-spin text-gray-200 dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default TicketsSingle
