import {useState} from 'react'
import {ApiService} from '../../services/data.service'
import {getDownloadURL, ref as ref_storage, uploadBytes} from 'firebase/storage'
import {uid} from 'uid'
import {db, storage} from '../../services/firebase'
import {AudioRecorder} from 'react-audio-voice-recorder'
import {ref, onValue, push} from 'firebase/database'
import moment from 'moment'
import {useParams} from 'react-router-dom'

const FileUploadChat = () => {
  const order_id = localStorage.getItem('order_id')
  const executor = localStorage.getItem('id_executor')
  const [loading, setLoading] = useState(false)

  const executorIDs = JSON.parse(localStorage.getItem('id_executor') || '{}')
  const idUser = JSON.parse(localStorage.getItem('id_user') || '{}')
  const chat_ids = useParams()

  const executorID = parseInt(executorIDs)

  const getDate = () => {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    const date = today.getDate()
    const time = moment().format('LT')
    return `${year}/${month}/${date} ${time}`
  }

  const handleChangeFile = (value: any) => {
    setLoading(true)
    ApiService.uploadFileChat({
      file: value.target.files[0],
    })
      .then((response) => {
        setLoading(false)
        if (response.data) {
          setTimeout(() => {
            push(ref(db, `/Chat/Orders/${chat_ids.order_id}`), {
              fileName: value.target.files[0].name,
              fileSize: value.target.files[0].size,
              providerId: idUser,
              seekerId: chat_ids.user_id,
              message: response.data,
              timeMessage: getDate(),
              type: 3,
            })
          }, 1000)
          onValue(ref(db, 'Chat/Orders/' + chat_ids.order_id), (snapshot) => {
            const data = snapshot.val()
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChangeImg = (value: any) => {
    setLoading(true)
    ApiService.uploadFileChat({
      file: value.target.files[0],
    })
      .then((response) => {
        setLoading(false)
        if (response.data) {
          setTimeout(() => {
            push(ref(db, `/Chat/Orders/${chat_ids.order_id}`), {
              fileName: value.target.files[0].name,
              fileSize: value.target.files[0].size,
              providerId: idUser,
              seekerId: chat_ids.user_id,
              message: response.data,
              timeMessage: getDate(),
              type: 2,
            })
          }, 1000)
          onValue(ref(db, 'Chat/Orders/' + chat_ids.order_id), (snapshot) => {
            const data = snapshot.val()
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const addAudioElement = async (blob: Blob) => {
    setLoading(true)
    const url = URL.createObjectURL(blob)
    const audio = document.createElement('audio')
    audio.src = url
    audio.controls = true
    const audioBlob = await fetch(url).then((r) => r.blob())
    const id = 'id' + Math.random().toString(16).slice(2)
    const audioFile = new File([audioBlob], `${id}voice.wav`, {
      type: 'audio/mp3',
    })

    ApiService.uploadFileChat({
      file: audioFile,
    })
      .then((response) => {
        setLoading(false)
        if (response.data) {
          setTimeout(() => {
            push(ref(db, `/Chat/Orders/${chat_ids.order_id}`), {
              fileName: audioFile?.name,
              fileSize: audioFile?.size,
              providerId: idUser,
              seekerId: chat_ids.user_id,
              message: response.data,
              timeMessage: getDate(),
              type: 4,
            })
          }, 1000)
        }
      })
      .catch((err) => {
        console.log(err)
      })

    // const dataId = `${order_id}-IDProject-${executor}-IDProvider`
    // const imagRef = ref_storage(storage, `Audios/${audioFile.name + uid()}`)
    // uploadBytes(imagRef, audioFile).then((response: any) => {
    //   if (response) {
    //     setLoading(false)
    //     getDownloadURL(response.ref).then((voiceUrl: any) => {
    //       push(ref_database(db, `/chat/${dataId}`), {
    //         idSender: executorID,
    //         text: voiceUrl,
    //         messagePosition: 0,
    //         sendTime: getDate(),
    //         type: 'audio',
    //       })
    //     })
    //   }
    // })
  }

  return (
    <div className={`${loading ? 'chat-btns loading' : 'chat-btns'}`}>
      {loading && <div className="loader"></div>}
      <label>
        <i className="fa fa-paperclip"></i>
        <input
          type="file"
          name="attachment"
          onChange={handleChangeFile}
          id="chat_attachment"
          accept="application/pdf"
        />
      </label>
      <label>
        <i className="fa fa-image"></i>
        <input
          type="file"
          name="image"
          onChange={handleChangeImg}
          id="chat_image"
        />
      </label>
      <label>
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          onNotAllowedOrFound={(err) => console.table(err)}
          downloadOnSavePress={false}
          downloadFileExtension="mp3"
          mediaRecorderOptions={{
            audioBitsPerSecond: 128000,
          }}
          showVisualizer={true}
        />
      </label>
    </div>
  )
}

export default FileUploadChat
