import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {ApiService, makeRequest} from '../../services/data.service'
import {FooterItem} from './FooterType'

export type IFooterSettings = {
  logo?: string
  website_name?: string
  primary_color: string
  secondary_color: string
  activate_color: string
  copyright: string
}

export const Footer = () => {
  const {t} = useTranslation()

  const [footerData, setFooterData] = useState<FooterItem>()

  const [settingsData, setSettingsData] = useState<IFooterSettings>()

  useEffect(() => {
    makeRequest
    .ApiService('media/home')
    .then((response: any) => {
      setFooterData(response.data?.footer)
    })
    .catch((err: any) => {
      console.log(err)
    })
    ApiService.Settings()
      .then((response) => {
        setTimeout(() => {
          setSettingsData(response.data)
        }, 200)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <footer className="tcon-footer-wrapper relative -z-0 pb-10 pt-20 lg:pb-20 lg:pt-[60px]">
      <div className="container mx-auto max-w-screen-xl">
        <div className="flex flex-wrap items-center justify-between">
          <div className="w-full px-4 sm:w-2/3 lg:w-3/12">
            <div className="mb-10 w-full">
              <Link to="/" className="mb-6 inline-block max-w-[160px]">
                <img
                  src={settingsData && settingsData.logo}
                  alt={settingsData && settingsData.website_name}
                  className="max-w-full"
                />
              </Link>
              <p className="mb-7 text-justify text-sm text-white">
                {footerData && footerData.description}
              </p>
            </div>
          </div>
          <div className="w-full px-4 sm:w-2/3 lg:w-2/12"></div>
          <div className="w-full justify-items-end px-4 sm:w-1/2 lg:w-7/12">
            <div className="mb-10 flex w-full flex-wrap justify-center lg:justify-end">
              <h4 className="me-3 text-3xl font-semibold text-white">
                {t('contactus')}
              </h4>
              <div className="footer-social flex items-center">
                  <Link
                    to={`${footerData && footerData.x_url}`}
                    className="hover:bg-primary hover:border-primary mr-3 flex h-8 w-8 items-center justify-center rounded-full text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <i className="fab fa-x-twitter"></i>
                  </Link>
                  <Link
                    to={`${footerData && footerData.instagram_url}`}
                    className="hover:bg-primary hover:border-primary mr-3 flex h-8 w-8 items-center justify-center rounded-full text-white hover:text-white sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-max">
        <span className="absolute bottom-0 left-0 z-[-1]">
          <svg
            width="217"
            height="229"
            viewBox="0 0 217 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
              fill="url(#paint0_linear_1179_5)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1179_5"
                x1="76.5"
                y1="281"
                x2="76.5"
                y2="1.22829e-05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#3056D3" stopOpacity="0.08" />
                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className="absolute right-10 top-10 z-[-1]">
          <svg
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
              fill="url(#paint0_linear_1179_4)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1179_4"
                x1="-1.63917e-06"
                y1="37.5"
                x2="75"
                y2="37.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#13C296" stopOpacity="0.31" />
                <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
      <div className="mx-auto max-w-screen-xl">
        <div className="items-center justify-between border-t border-gray-700 pt-8 sm:flex">
          <ul className="flex flex-wrap justify-center gap-4 text-xs lg:justify-end">
            <li>
              <Link
                to={'/about-us'}
                className="text-white transition hover:opacity-75"
              >
                {t('about')}
              </Link>
            </li>
            <li>
              <Link
                to={'/contact-us'}
                className="text-white transition hover:opacity-75"
              >
                {t('contact')}
              </Link>
            </li>
            <li>
              <Link
                to={'/privacy-policy'}
                className="text-white transition hover:opacity-75"
              >
                {t('privacypolicy')}
              </Link>
            </li>
            <li>
              <Link
                to={'/terms'}
                className="text-white transition hover:opacity-75"
              >
                {t('terms')}
              </Link>
            </li>
          </ul>
          <p className="mb-3 mt-8 text-center text-white md:mb-0 md:mt-0">
            {footerData && footerData.copyright}
          </p>
          {/* <img className="m-auto md:m-0" src={visagImg} alt="visa" /> */}
        </div>
      </div>
    </footer>
  )
}
